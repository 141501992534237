<script>
import imgDummy from "@/assets/images/splash_logo.png";
import skeletonLoading from "@/components/skeleton";

export default {
  components: { skeletonLoading },
  data() {
    return {
      apiUrl: {
        cloths: process.env.VUE_APP_API_BASE_URL + "clothes",
        clothPhoto: process.env.VUE_APP_API_BASE_URL + "cloth-photos",
        reachedMinStock: process.env.VUE_APP_API_BASE_URL + "dashboard/reached-min-stock",
      },
      product: [],
      clothPhoto: [],
      isLoading: false,
      imgDummy: imgDummy,
      load: 5,
    };
  },
  methods: {
    /* GET DATA LIST PRODUCT */
    async getProduct() {
      try {
        this.isLoading = true;
        await this.$http.get(`${this.apiUrl.reachedMinStock}`).then((response) => {
          this.product = response.data;
          console.log(this.product)
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    /* END GET DATA LIST PRODUCT */
  },
  mounted() {
    this.getProduct();
  },
  middleware: "authentication",
};
</script>

<template>
  <div class="col-xl-4">
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col md="6"><h4 class="card-title mb-4">Batas Min. Stok</h4></b-col>
          <b-col md="6" class="text-right"><span class="font-size-15 mb-1 fw-bold">Total ({{ product.total_reached_min_stock }})</span></b-col>
        </b-row>
        <div data-simplebar style="max-height: 336px;">
          <!-- CARD DATA -->
          <div class="table-responsive" v-if="!isLoading">
            <table class="table table-borderless table-centered table-nowrap">
              <thead>
                <tr>
                  <th></th>
                  <th>Produk</th>
                  <th class="text-center">Min. Stok</th>
                  <th class="text-center">Stok</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in product.reached_min_stock" :key="item.id">
                  <td style="width: 20px;">
                    <img
                      :src="
                        item.photo
                          ? item.photo
                          : imgDummy
                      "
                      class="avatar-sm rounded-circle shadow"
                      alt="..."
                      style="object-fit: cover;"
                    />
                  </td>
                  <td>
                    <a :href="'#/produk/' + item.cloth_id" class="font-size-15 mb-1 fw-bold">
                      {{ item.cloth_name }}
                    </a>
                  </td>
                  <td class="text-center">
                    <a :href="'#/produk/' + item.cloth_id" class="font-size-15 mb-1 fw-bold">
                      {{ item.min_stock }}
                    </a>
                  </td>
                  <td class="text-center">
                    <a :href="'#/produk/' + item.cloth_id" class="font-size-15 mb-1 fw-bold">
                      {{ item.stock }}
                    </a>
                  </td>
                  <!-- <td
                    class="text-muted fw-semibold"
                    style="width: 20px !important;"
                  >
                    <template v-for="size in item.cloth_colors">
                      <template v-for="(min, index) in size.cloth_sizes">
                        {{ min.min_stock }}
                      </template>
                    </template>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END CARD DATA -->

          <!-- SKELETON LOADING -->
          <div class="table-responsive" v-else>
            <table class="table table-borderless table-centered table-nowrap">
              <tbody>
                <tr v-for="(data, index) in load" :key="index">
                  <td style="width: 20px;">
                    <skeletonLoading
                      width="50px"
                      height="50px"
                      borderRadius="50%"
                    />
                  </td>
                  <td>
                    <skeletonLoading
                      width="100%"
                      height="35px"
                      borderRadius="20px"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END SKELETON LOADING -->
        </div>
      </div>
    </div>
  </div>
</template>
