<script>
import countTo from "vue-count-to";

/**
 * Sales-analytics component
 */
export default {
  components: {
    countTo,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 2, 2],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        colors: ["#5b73e8", "#dfe2e6", "#f1b44c"],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [],
        markers: {
          size: 2,
        },
        xaxis: {
          //   type: "datetime",
        },
        yaxis: {
          title: {
            // text: "Points",
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      // selectedFilter: "Weekly",
    };
  },
  mounted() {
    this.fetchChartData("weekly");
  },
  methods: {
    fetchChartData(range) {
      this.$http
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `dashboard/sales-analytics?range=${range}`
        )
        .then((response) => {
          this.updateChartData(response.data, range);
        })
        .catch((error) => {
          console.error("Error fetching data: " + error);
        });
    },
    updateChartData(data, range) {
      this.series = [
        {
          name: "Lunas",
          type: "column",
          data: data.map((item) => item.count_lunas),
        },
        {
          name: "Pending",
          type: "area",
          data: data.map((item) => item.count_pending),
        },
        {
          name: "Batal",
          type: "line",
          data: data.map((item) => item.count_batal),
        },
      ];

      let labels = [];
      if (range === "weekly") {
        labels = [
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
          "Minggu",
        ];
      } else if (range === "monthly") {
        labels = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
      } else if (range === "yearly") {
        const currentYear = new Date().getFullYear();
        for (let year = 2001; year <= currentYear; year++) {
          labels.push(String(year));
        }
      }

      this.chartOptions = {
        ...this.chartOptions,
        labels: labels,
      };
    },
    selectFilter(filter) {
      this.selectedFilter = filter.charAt(0).toUpperCase() + filter.slice(1);
      this.fetchChartData(filter);
    },
  },
};
</script>

<template>
  <div class="col-xl-12">
    <div class="card" style="height: 500px;">
      <div class="card-body">
        <div class="float-end">
          <b-dropdown
            variant="white"
            toggle-class="text-reset p-0"
            menu-class="dropdown-menu-end"
          >
            <!-- <template v-slot:button-content>
              <span class="fw-semibold">Filter:</span>
              <span class="text-muted">
                {{ selectedFilter }}
                <i class="mdi mdi-chevron-down ms-1"></i>
              </span>
            </template> -->
            <a @click="selectFilter('weekly')" class="dropdown-item" href="#"
              >Weekly</a
            >
            <a @click="selectFilter('monthly')" class="dropdown-item" href="#"
              >Monthly</a
            >
            <a @click="selectFilter('yearly')" class="dropdown-item" href="#"
              >Yearly</a
            >
          </b-dropdown>
        </div>
        <h4 class="card-title mb-4">Analisis Penjualan</h4>

        <!-- <div class="mt-1">
          <ul class="list-inline main-chart mb-0">
            <li class="list-inline-item chart-border-left me-0 border-0">
              <h3 class="text-primary">
                $
                <span data-plugin="counterup">
                  <countTo
                    :startVal="1"
                    :endVal="2371"
                    :duration="2000"
                  ></countTo>
                </span>
                <span class="text-muted d-inline-block font-size-15 ms-3"
                  >Income</span
                >
              </h3>
            </li>
            <li class="list-inline-item chart-border-left me-0">
              <h3>
                <span data-plugin="counterup">
                  <countTo
                    :startVal="1"
                    :endVal="258"
                    :duration="2000"
                  ></countTo>
                </span>
                <span class="text-muted d-inline-block font-size-15 ms-3"
                  >Sales</span
                >
              </h3>
            </li>
            <li class="list-inline-item chart-border-left me-0">
              <h3>
                <span data-plugin="counterup">3.6</span>%
                <span class="text-muted d-inline-block font-size-15 ms-3"
                  >Conversation Ratio</span
                >
              </h3>
            </li>
          </ul>
        </div> -->

        <div class="mt-3">
          <apexchart
            type="line"
            class="apex-charts"
            dir="ltr"
            height="339"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
      <!-- end card-body-->
    </div>
    <!-- end card-->
  </div>
  <!-- end col-->
</template>
