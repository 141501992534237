<script>
import countTo from "vue-count-to";
import Skeleton from "@/components/skeleton.vue";
import axios from "axios";

/**
 * Stat component
 */
export default {
  components: {
    countTo,
    Skeleton,
  },
  data() {
    return {
      dataDashboard: null,
      isLoading: null,
      apiUrl: {
        dashboard: process.env.VUE_APP_API_BASE_URL + "dashboard/counts",
      },
      role: null,
      totalRevenue: 0,
      totalOrder: 0,
      series: [
        {
          data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
        },
      ],
      chartOptions: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      growthChartOptions: {
        fill: {
          colors: ["#f1b44c"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      orderseries: [],
      orderRadial: {
        fill: {
          colors: ["#34c38f"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
      customerseries: [],
      customerRadial: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
    };
  },
  methods: {
    async getDataDashboard() {
      this.isLoading = true;
      const { data } = await axios.get(this.apiUrl.dashboard);
      this.isLoading = false;

      this.orderseries = [data.order_total];
      this.customerseries = [data.customer_total];

      return data;
    },
    async getDataStat() {
      const data = await this.$http.get(
        process.env.VUE_APP_API_BASE_URL + "orders"
      );
      const datas = await data.data.data;
    },
  },
  async mounted() {
    const { role } = JSON.parse(localStorage.getItem("account_data"));
    this.dataDashboard = await this.getDataDashboard();
    this.getDataStat();
  },
};
</script>
<template>
  <div class="row" v-if="dataDashboard !== null">
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Keuntungan -->
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart
              class="apex-charts"
              dir="ltr"
              width="70"
              height="40"
              :options="chartOptions"
              :series="series"
            >
            </apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              Rp
              <span data-plugin="counterup">
                <countTo
                  :startVal="0"
                  :endVal="Number(dataDashboard.income_total)"
                  :duration="3000"
                ></countTo>
              </span>
            </h4>
            <p class="text-muted mb-0">Total Pendapatan</p>
          </div>
          <!-- <p class="text-muted mt-3 mb-0">
            <span class="text-success me-1">
              <i class="mdi mdi-arrow-up-bold me-1"></i>
              <span v-text="1 * (totalRevenue + 10 - totalRevenue)"></span>%
            </span>
            naik dari minggu lalu
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Pesanan -->
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart
              class="apex-charts"
              type="radialBar"
              dir="ltr"
              width="45"
              height="45"
              :options="orderRadial"
              :series="orderseries"
            ></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              <span data-plugin="counterup">
                <countTo
                  :startVal="0"
                  :endVal="Number(dataDashboard.order_total)"
                  :duration="2000"
                ></countTo>
              </span>
            </h4>
            <p class="text-muted mb-0">Pesanan</p>
          </div>
          <!-- <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>0.82%
            </span>
            turun dari minggu lalu
          </p> -->
        </div>
      </div>
    </div>
    <!-- end col-->

    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Pelanggan -->
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart
              class="apex-charts"
              type="radialBar"
              dir="ltr"
              width="45"
              height="45"
              :options="customerRadial"
              :series="customerseries"
            ></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              <span data-plugin="counterup">
                <countTo
                  :startVal="0"
                  :endVal="Number(this.dataDashboard.customer_total)"
                  :duration="2000"
                ></countTo>
              </span>
            </h4>
            <p class="text-muted mb-0">Pelanggan</p>
          </div>
          <!-- <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
            </span>
            turun dari minggu lalu
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Perkembangan -->
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart
              class="apex-charts"
              dir="ltr"
              width="70"
              height="40"
              :options="growthChartOptions"
              :series="series"
            ></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              +
              <span data-plugin="counterup">{{ dataDashboard.growth }}</span>
            </h4>
            <p class="text-muted mb-0">Pertumbuhan</p>
          </div>
          <!-- <p class="text-muted mt-3 mb-0">
            <span class="text-success me-1">
              <i class="mdi mdi-arrow-up-bold me-1"></i>10.51%
            </span>
            naik dari minggu lalu
          </p> -->
        </div>
      </div>
    </div>
    <!-- end col-->
  </div>
  <!-- end row-->
  <div class="row" v-else>
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Keuntungan -->
        <div class="card-body costume-body">
          <div class="float-end mt-2"></div>
          <p class="text-muted mb-0 mx-auto text-center">
            <skeleton
              width="200px"
              height="75px"
              borderRadius="7px"
              class=" mb-2"
            />
            <skeleton width="200px" height="25px" borderRadius="7px" />
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Keuntungan -->
        <div class="card-body costume-body">
          <div class="float-end mt-2"></div>
          <p class="text-muted mb-0 mx-auto text-center">
            <skeleton
              width="200px"
              height="75px"
              borderRadius="7px"
              class=" mb-2"
            />
            <skeleton width="200px" height="25px" borderRadius="7px" />
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Keuntungan -->
        <div class="card-body costume-body">
          <div class="float-end mt-2"></div>
          <p class="text-muted mb-0 mx-auto text-center">
            <skeleton
              width="200px"
              height="75px"
              borderRadius="7px"
              class=" mb-2"
            />
            <skeleton width="200px" height="25px" borderRadius="7px" />
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card">
        <!-- Bagian Keuntungan -->
        <div class="card-body costume-body">
          <div class="float-end mt-2"></div>
          <p class="text-muted mb-0 mx-auto text-center">
            <skeleton
              width="200px"
              height="75px"
              borderRadius="7px"
              class=" mb-2"
            />
            <skeleton width="200px" height="25px" borderRadius="7px" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.costume-body {
  height: 153px;
}
</style>
