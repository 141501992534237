<script>
/**
 * Selling-product component
 */

import skeletonLoading from "@/components/skeleton";

export default {
  components: { skeletonLoading },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL + "report/cloth-favorites",
      dataTopSelling: [],
      isLoading: true,
      dataLoad: 14,
    };
  },
  methods: {
    async getTopSelling() {
      try {
        this.isLoading = true;
        await this.$http.get(`${this.apiUrl}`).then((response) => {
          this.dataTopSelling = response.data.data;
          this.isLoading = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getTopSelling();
  },
  computed: {
    getVariant() {
      return (totalQty) => {
        const colors = ["primary", "info", "success", "warning", "purple"];
        const index = totalQty % colors.length;
        return colors[index];
      };
    },
  },
};
</script>

<template>
  <div class="col-xl-4">
    <div class="card wrap">
      <div class="card-body">
        <!-- <div class="float-end">
          <b-dropdown
            right
            toggle-class="text-reset p-0"
            variant="white"
            menu-class="dropdown-menu-end"
          >
            <template v-slot:button-content>
              <span class="fw-semibold">Sort By:</span>
              <span class="text-muted">
                Yearly
                <i class="mdi mdi-chevron-down ms-1"></i>
              </span>
            </template>
            <a class="dropdown-item" href="#">Monthly</a>
            <a class="dropdown-item" href="#">Yearly</a>
            <a class="dropdown-item" href="#">Weekly</a>
          </b-dropdown>
        </div> -->

        <h4 class="card-title mb-4">Produk Terlaris</h4>

        <div class="p-2 top-selling" v-if="!isLoading">
          <div
            class="row align-items-center no-gutters mb-3"
            v-for="selling in dataTopSelling"
            :key="selling.id"
          >
            <div class="col-sm-5">
              <p class="text-truncate mt-1 mb-0">
                <i
                  class="mdi mdi-circle-medium me-2 primary"
                  :class="`text-${getVariant(selling.total_qty)}`"
                ></i>
                {{ selling.category_name ? selling.category_name : "-" }}
                <!-- {{
                  selling.order_details[0].cloth_stock
                    ? selling.order_details[0].cloth_stock.cloth_color
                      ? selling.order_details[0].cloth_stock.cloth_color.cloth
                        ? selling.order_details[0].cloth_stock.cloth_color.cloth
                            .cloth_category
                          ? selling.order_details[0].cloth_stock.cloth_color.cloth
                              .cloth_category.name || "-"
                          : "-"
                        : "-"
                      : "-"
                    : "-"
                }} -->
              </p>
            </div>

            <div class="col-sm-5">
              <b-progress
                :value="selling.total_qty"
                :variant="getVariant(selling.total_qty)"
                class="mt-1"
                height="6px"
              ></b-progress>
            </div>
          </div>
        </div>
        <div v-else class="p-2 top-selling">
          <div
            class="row align-items-center no-gutters mb-3"
            v-for="load in dataLoad"
            :key="load"
          >
            <div class="col-sm-1">
              <skeletonLoading width="100%" height="10px" borderRadius="100px" />
            </div>
            <div class="col-sm-4">
              <skeletonLoading width="100%" height="10px" borderRadius="100px" />
            </div>
            <div class="col-sm-7">
              <skeletonLoading width="100%" height="10px" borderRadius="100px" />
            </div>
          </div>
        </div>
      </div>
      <!-- end card-body-->
    </div>
    <!-- end card-->
  </div>
</template>

<style scoped>
.wrap {
  overflow: hidden;
  height: auto;
}

.top-selling {
  scrollbar-width: thin;
  overflow: auto;
  max-height: 400px;
  overflow-x: hidden;
}

.top-selling::-webkit-scrollbar {
  width: 5px;
}

.top-selling::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.top-selling::-webkit-scrollbar-thumb {
  background-color: #d1d5da;
  outline: 1px solid #d1d5da;
  border-radius: 50px;
}
</style>
