<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/stat";
import SalesAnalytics from "./sales-analytics";
import SellingProduct from "./selling-product";
import TopUsers from "./top-users";
import Activity from "./activity";
import SocialSource from "./social-source";
import axios from "axios";

export default {
  page: {
    title: "Dashboard - Stage Dev",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    SalesAnalytics,
    SellingProduct,
    TopUsers,
    Activity,
    SocialSource,
  },
  data() {
    return {
      title: "Kaosnyaman",
      items: [
        {
          text: "Kaosnyaman",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      apiUrl: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        customerCategory:
          process.env.VUE_APP_API_BASE_URL + "customer-categories",
        customer: process.env.VUE_APP_API_BASE_URL + "customers",
        order: process.env.VUE_APP_API_BASE_URL + "orders",
        clothColor: process.env.VUE_APP_API_BASE_URL + "cloth-colors",
        cloth: process.env.VUE_APP_API_BASE_URL + "clothes",
        dashboard: process.env.VUE_APP_API_BASE_URL + "dashboard/counts",
      },
    };
  },
  methods: {
    async getDashoardHeader() {
      const { data } = await axios.get(this.apiUrl.dashboard);
      return data;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Stat />
    <div class="row">
      <SalesAnalytics />
    </div>
    <div class="row">
      <TopUsers />
      <Activity />
      <SellingProduct />
    </div>
  </Layout>
</template>
